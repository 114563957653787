<template>
  <div class="flex flex-col w-full h-full min-h-full">
    <AppHeader></AppHeader>
    <div class="flex flex-grow overflow-hidden">
      <AppAside />
      <div class="flex flex-col w-full h-full bg-secondary relative overflow-hidden">
        <div class="h-full overflow-y-auto rounded-bl-lg rounded-br-lg bg-primary-body">
          <slot></slot>
        </div>
        <AppFooter></AppFooter>
      </div>
    </div>
    <filter-panel :panelOpen="isOpenFilterPanel"></filter-panel>
  </div>
</template>

<script>
import AppHeader from '@/components/core/AppHeader.vue'
import AppAside from '@/components/core/AppAside.vue'
import AppFooter from '@/components/core/AppFooter.vue'
import FilterPanel from '@/components/FilterPanel.vue'

export default {
  name: 'PrimaryLayout',
  components: {
    AppHeader,
    AppAside,
    AppFooter,
    FilterPanel
  },
  computed: {
    isOpenFilterPanel: function () {
      return this.$store.getters['app/isOpenFilterPanel']
    }
  }
}
</script>
