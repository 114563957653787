<template>
  <panel-layout :isOpen="panelOpen" @closePanel="closePanel">
    <template slot="header">
      <div class="flex items-center justify-between pt-6 pl-6 pb-3 pr-4 bg-white shadow">
        <div class="flex flex-col">
          <h3 class="font-bold text-3xl">{{ $t('app.filterBy') }}</h3>
          <span class="text-sm">{{ $tc('app.filterCriteriaCount', criteriaCounter) }}</span>
        </div>
        <span class="cursor-pointer w-8 h-8 rounded-full flex items-center justify-center bg-primary-50" @click="closePanel">
          <base-icon name="cross" size="xs"/>
        </span>
      </div>
    </template>
    <template slot="main">
      <div class="p-1 pt-4">
        <div v-if="filter1" class="bg-primary-body p-2 mb-3 pl-5 rounded-lg shadow">
          <base-collapsible  id="dimension1"  :open="collapseDimensions.dimension1" customContent="bg-primary-body" @collapseStatus="collapseStatus('dimension1')">
            <template slot="headerRight">
              <div class="flex flex-col py-2">
                <h3 class="font-bold text-2xl">{{ filter1.label }}</h3>
              </div>
            </template>
            <template slot="content">
              <filter-list v-model="selected.filter1" :data="filter1.values" />
            </template>
          </base-collapsible>
        </div>
        <div v-if="filter2" class="bg-primary-body p-2 mb-3 pl-5 rounded-lg shadow">
          <base-collapsible  id="dimension1"  :open="collapseDimensions.dimension2" customContent="bg-primary-body" @collapseStatus="collapseStatus('dimension2')">
            <template slot="headerRight">
              <div class="flex flex-col py-2">
                <h3 class="font-bold text-2xl">{{ filter2.label }}</h3>
              </div>
            </template>
            <template slot="content">
              <filter-list v-model="selected.filter2" :data="filter2.values" />
            </template>
          </base-collapsible>
        </div>
        <div v-if="filter3" class="bg-primary-body p-2 mb-3 pl-5 rounded-lg shadow">
          <base-collapsible  id="dimension1"  :open="collapseDimensions.dimension3" customContent="bg-primary-body" @collapseStatus="collapseStatus('dimension3')">
            <template slot="headerRight">
              <div class="flex flex-col py-2">
                <h3 class="font-bold text-2xl">{{ filter3.label }}</h3>
              </div>
            </template>
            <template slot="content">
              <filter-list v-model="selected.filter3" :data="filter3.values" />
            </template>
          </base-collapsible>
        </div>
        <div v-if="filter4" class="bg-primary-body p-2 mb-3 pl-5 rounded-lg shadow">
          <base-collapsible  id="dimension1"  :open="collapseDimensions.dimension4" customContent="bg-primary-body" @collapseStatus="collapseStatus('dimension4')">
            <template slot="headerRight">
              <div class="flex flex-col py-2">
                <h3 class="font-bold text-2xl">{{ filter4.label }}</h3>
              </div>
            </template>
            <template slot="content">
              <filter-list v-model="selected.filter4" :data="filter4.values" />
            </template>
          </base-collapsible>
        </div>
        <div v-if="filter5" class="bg-primary-body p-2 mb-3 pl-5 rounded-lg shadow">
          <base-collapsible  id="dimension1"  :open="collapseDimensions.dimension5" customContent="bg-primary-body" @collapseStatus="collapseStatus('dimension5')">
            <template slot="headerRight">
              <div class="flex flex-col py-2">
                <h3 class="font-bold text-2xl">{{ filter5.label }}</h3>
              </div>
            </template>
            <template slot="content">
              <filter-list v-model="selected.filter5" :data="filter5.values" />
            </template>
          </base-collapsible>
        </div>

        <div class="bg-primary-body p-2 mb-3 pl-5 rounded-lg shadow">
          <base-collapsible  id="dimension1"  :open="collapseDimensions.healthIndex" customContent="bg-primary-body" @collapseStatus="collapseStatus('healthIndex')">
            <template slot="headerRight">
              <div class="flex flex-col py-2">
                <h3 class="font-bold text-2xl">{{ $t('app.healthIndex') }}</h3>
              </div>
            </template>
            <template slot="content">
              <filter-list v-model="selected.healthIndex" :data="valuesHealthIndexFilter" />
            </template>
          </base-collapsible>
        </div>

      </div>
    </template>
    <template slot="footer">
      <base-button @click="closePanel" mode="soft" :text="$t('app.cancel')"/>
      <base-button @click="applyFilters" class="w-full" :text="$t('app.apply')"/>
    </template>
  </panel-layout>
</template>
<script>
import PanelLayout from '@/layouts/PanelLayout'
import FilterList from '@/components/FilterList.vue'
export default {
  name: 'filter-panel',
  props: {
    panelOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PanelLayout,
    FilterList
  },
  data: function () {
    return {
      isOpen: this.panelOpen,
      collapseDimensions: {
        dimension1: false,
        dimension2: false,
        dimension3: false,
        dimension4: false,
        dimension5: false,
        healthIndex: false
      },
      selected: {
        filter1: [],
        filter2: [],
        filter3: [],
        filter4: [],
        filter5: [],
        healthIndex: []
      },
      initialSelected: {
        filter1: [],
        filter2: [],
        filter3: [],
        filter4: [],
        filter5: [],
        healthIndex: []
      }
    }
  },
  computed: {
    filters: function () {
      return this.$store.getters['filters/dimensions']
    },
    filter1: function () {
      const filter1 = this.filters.filter1
      if (filter1 && filter1.values && Object.keys(filter1.values).length) {
        const label = this.getLabelFilter(filter1)
        return {
          name: 'filter1',
          label: label,
          values: Object.keys(filter1.values).map(key => {
            return {
              name: 'filter1',
              label: key,
              value: key,
              count: filter1.values[key]
            }
          })
        }
      }

      return null
    },
    filter2: function () {
      const filter2 = this.filters.filter2
      if (filter2 && filter2.values && Object.keys(filter2.values).length) {
        const label = this.getLabelFilter(filter2)
        return {
          name: 'filter2',
          label: label,
          values: Object.keys(filter2.values).map(key => {
            return {
              name: 'filter2',
              label: key,
              value: key,
              count: filter2.values[key]
            }
          })
        }
      }

      return null
    },
    filter3: function () {
      const filter3 = this.filters.filter3
      if (filter3 && filter3.values && Object.keys(filter3.values).length) {
        const label = this.getLabelFilter(filter3)
        return {
          name: 'filter3',
          label: label,
          values: Object.keys(filter3.values).map(key => {
            return {
              name: 'filter3',
              label: key,
              value: key,
              count: filter3.values[key]
            }
          })
        }
      }

      return null
    },
    filter4: function () {
      const filter4 = this.filters.filter4
      if (filter4 && filter4.values && Object.keys(filter4.values).length) {
        const label = this.getLabelFilter(filter4)
        return {
          name: 'filter4',
          label: label,
          values: Object.keys(filter4.values).map(key => {
            return {
              name: 'filter4',
              label: key,
              value: key,
              count: filter4.values[key]
            }
          })
        }
      }

      return null
    },
    filter5: function () {
      const filter5 = this.filters.filter5
      if (filter5 && filter5.values && Object.keys(filter5.values).length) {
        const label = this.getLabelFilter(filter5)
        return {
          name: 'filter5',
          label: label,
          values: Object.keys(filter5.values).map(key => {
            return {
              name: 'filter5',
              label: key,
              value: key,
              count: filter5.values[key]
            }
          })
        }
      }

      return null
    },
    healthIndexFilter: function () {
      return this.$store.getters['filters/healthIndexFilter']
    },
    valuesHealthIndexFilter: function () {
      return [
        { label: this.$t('app.approveds'), value: true, name: 'healthIndex' },
        { label: this.$t('app.noApproveds'), value: false, name: 'healthIndex' }
      ]
    },
    selectedHealthIndexFilter: function () {
      return this.$store.getters['filters/healthIndexFilter'].selected
    },
    selectedFilter1: function () {
      return this.$store.getters['filters/selectedDimensions'].filter1
    },
    selectedFilter2: function () {
      return this.$store.getters['filters/selectedDimensions'].filter2
    },
    selectedFilter3: function () {
      return this.$store.getters['filters/selectedDimensions'].filter3
    },
    selectedFilter4: function () {
      return this.$store.getters['filters/selectedDimensions'].filter4
    },
    selectedFilter5: function () {
      return this.$store.getters['filters/selectedDimensions'].filter5
    },
    criteriaCounter: function () {
      const counter = this.selected.filter1.length + this.selected.filter2.length + this.selected.filter3.length + this.selected.filter4.length + this.selected.filter5.length

      return counter + this.selectedHealthIndexFilter.length
    },
    changeFiltersCounter: function () {
      return this.$store.getters['filters/changeFiltersCounter']
    }
  },
  created: function () {
    this.initData()
  },
  methods: {
    initData: function () {
      this.selected.filter1 = [...this.selectedFilter1]
      this.selected.filter2 = [...this.selectedFilter2]
      this.selected.filter3 = [...this.selectedFilter3]
      this.selected.filter4 = [...this.selectedFilter4]
      this.selected.filter5 = [...this.selectedFilter5]
      this.selected.healthIndex = [...this.selectedHealthIndexFilter]

      this.collapseDimensions.dimension1 = !!this.selectedFilter1.length
      this.collapseDimensions.dimension2 = !!this.selectedFilter2.length
      this.collapseDimensions.dimension3 = !!this.selectedFilter3.length
      this.collapseDimensions.dimension4 = !!this.selectedFilter4.length
      this.collapseDimensions.dimension5 = !!this.selectedFilter5.length
      this.collapseDimensions.healthIndex = !!this.selectedHealthIndexFilter.length
    },
    collapseStatus: function (id) {
      this.collapseDimensions[id] = !this.collapseDimensions[id]
    },
    closePanel: function () {
      this.$store.commit('app/setIsOpenFilterPanel', false)
    },
    applyFilters: function () {
      this.$store.commit('filters/setSelectedFilter1', this.selected.filter1)
      this.$store.commit('filters/setSelectedFilter2', this.selected.filter2)
      this.$store.commit('filters/setSelectedFilter3', this.selected.filter3)
      this.$store.commit('filters/setSelectedFilter4', this.selected.filter4)
      this.$store.commit('filters/setSelectedFilter5', this.selected.filter5)
      this.$store.commit('filters/setHealthIndexFilterSelected', this.selected.healthIndex)

      this.$store.commit('filters/incrementChangeCounter')

      this.closePanel()
    },
    getLabelFilter: function (filter) {
      const locale = this.$store.getters['auth/locale']

      if (filter.i18n && filter.i18n[locale]) {
        return filter.i18n[locale]
      }

      return filter.name
    }
  },
  watch: {
    changeFiltersCounter: function () {
      this.initData()
    }
  }
}
</script>

<style lang="sass" scoped></style>
