<template>
  <footer class="h-12 flex-shrink-0 flex items-center text-secondary-50">
    <div class="flex-shrink-0 pl-4 pr-2 w-48">
      <div class="flex items-center">
        <div class="w-7 h-7 bg-secondary-800 rounded-full flex items-center justify-center">
          <base-icon
            name="filter"
            size="xs"
          />
        </div>
        <div class="text-xs ml-2">
          <span class="block">{{ $t('app.filterCriteria') }}</span>
          <span class="font-medium">{{ $tc('app.resultsCounter', resultsCounter) }}</span>
        </div>
      </div>
    </div>
    <base-filter-tags :tags="tags" @removeTag="removeTag" @removeAllTags="removeAllTags" />
    <nav class="flex flex-shrink-0 justify-end w-36 items-center pr-4">
      <div class="leading-none text-right mr-2">
        <span class="text-xs">{{ $t('app.healthIndex') }}</span>
        <span class="text-xs block font-bold">{{ healthIndexLabel }}</span>
      </div>
      <base-health-index :hIndex="healthIndex" />
    </nav>
  </footer>
</template>

<script>
import { getPercentFormat } from '@/i18n/numbers'

export default {
  name: 'AppFooter',
  computed: {
    resultsCounter: function () {
      return this.$store.getters['filters/resultsCounter']
    },
    healthIndex: function () {
      return this.$store.getters['filters/healthIndex']
    },
    healthIndexLabel: function () {
      if (this.healthIndex) {
        return getPercentFormat(this.healthIndex)
      }

      return '-'
    },
    selectedHealthIndexFilter: function () {
      const healthIndexFilter = this.$store.getters['filters/healthIndexFilter']
      const selectedValues = healthIndexFilter.selected
      const values = [
        { label: this.$t('app.approveds'), value: true, name: 'healthIndex' },
        { label: this.$t('app.noApproveds'), value: false, name: 'healthIndex' }
      ]

      return values.filter(item => selectedValues.includes(item.value))
    },
    tags: function () {
      const tags = []
      const selectedDimensions = this.$store.getters['filters/selectedDimensions']

      for (const key in selectedDimensions) {
        const filters = selectedDimensions[key]
        for (const filter of filters) {
          tags.push({
            key: key,
            label: filter,
            value: filter
          })
        }
      }

      for (const filter of this.selectedHealthIndexFilter) {
        tags.push({
          key: 'healthIndex',
          label: filter.label,
          value: filter.value
        })
      }

      return tags
    }
  },
  methods: {
    removeTag: function (tag) {
      if (tag.key !== 'healthIndex') {
        const selectedDimensions = this.$store.getters['filters/selectedDimensions']
        const filters = selectedDimensions[tag.key].filter(t => t !== tag.value)

        const key = tag.key[0].toUpperCase() + tag.key.substring(1)
        this.$store.commit('filters/setSelected' + key, filters)
        this.$store.commit('filters/incrementChangeCounter')
      } else {
        const filters = this.selectedHealthIndexFilter.map(item => item.value).filter(t => t !== tag.value)
        this.$store.commit('filters/setHealthIndexFilterSelected', filters)
        this.$store.commit('filters/incrementChangeCounter')
      }
      this.$store.commit('filters/incrementChangeFiltersCounter')
    },
    removeAllTags: function () {
      this.$store.commit('filters/resetSelectedFilters')
      this.$store.commit('filters/incrementChangeCounter')
      this.$store.commit('filters/incrementChangeFiltersCounter')
    }
  }
}
</script>
