<template>
  <transition name="slide-aside">
    <div
      v-if="isOpen"
      class="fixed w-full h-full md:w-96 top-0 right-0 p-4 z-50"
    >
      <div class="aside-filters shadow-2xl h-full z-50 flex flex-col select-none bg-white rounded-lg">
        <div class="overflow-y-auto flex flex-col h-full rounded-tl-lg rounded-tr-lg">
          <header v-if="hasHeader" class="sticky top-0 flex-none z-10">
            <slot name="header" />
          </header>
          <div class="h-full">
            <slot name="main" />
          </div>
        </div>
        <footer v-if="hasFooter" class="flex-none flex items-center justify-end space-x-2 px-2 bg-white py-2 mt-auto rounded-bl-lg rounded-br-lg">
          <slot name="footer" />
        </footer>
        <UtilKeys @keyup.esc="closePanel" />
      </div>
    </div>
  </transition>
</template>

<script>
import UtilKeys from '@/components/utilities/UtilKeys'
export default {
  name: 'filter-panel-layout',
  components: {
    UtilKeys
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasHeader: function () {
      return this.$scopedSlots.header
    },
    hasFooter: function () {
      return this.$scopedSlots.footer
    }
  },
  methods: {
    closePanel: function () {
      this.$emit('closePanel')
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-aside-enter-active,
.slide-aside-leave-active {
  transition: transform .25s;
}
.slide-enter {
  transform: translateX(0%);
}
.slide-aside-enter,
.slide-aside-leave-to {
  transition: transform 0.25s;
  transform: translateX(100%);
  // opacity: 0;
}
</style>
