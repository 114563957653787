<template>
  <li class="py-2">
    <span class="flex items-center justify-between">
      <base-input-checkbox v-model="localValue" :label="item.label" :name="item.name" :checked="checked" />
      <span v-if="isNumber" class="text-sm flex-none flex items-center justify-center px-2 py-1 bg-white rounded-full text-secondary font-medium shadow">{{ item.count }}</span>
    </span>
  </li>
</template>

<script>
export default {
  name: 'filter-list-item',
  props: {
    item: {
      type: Object,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      localValue: null
    }
  },
  computed: {
    isNumber: function () {
      return typeof this.item.count === 'number'
    }
  },
  watch: {
    localValue: function (value) {
      this.$emit('change', this.item.value, value)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
