<template>
  <ul>
    <filter-list-item :checked="value.includes(item.value)" @change="onChange" v-for="(item, index) in data" :key="index" :item="item" />
  </ul>
</template>

<script>
import FilterListItem from '@/components/FilterListItem.vue'
export default {
  name: 'filter-list',
  components: {
    FilterListItem
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      values: this.value
    }
  },
  methods: {
    onChange: function (value, checked) {
      if (checked) {
        this.values.push(value)
      } else {
        this.values = this.values.filter(v => v !== value)
      }

      this.$emit('input', this.values)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
